export const API_VERSION = "/api/v1";
const USER_LIST = `${API_VERSION}/users`;
const USER_LIST_EXPORT = `${API_VERSION}/users/export`;
const CITIES_LIST = `${API_VERSION}/cities`;
const COMPANIES_LIST = `${API_VERSION}/companies`;
const COMPANIES_LIST_EXPORT = `${API_VERSION}/companies/export`;
const COMPANIES_LIST_APPROVED = `${API_VERSION}/companies/approved`;
const COMPANIES_TYPE = `${API_VERSION}/company-types`;
const EVENTS_LIST = `${API_VERSION}/events`;
const DOCUMENTS_LIST = `${API_VERSION}/document`;
const CATEGORY_LIST = `${API_VERSION}/category`;
const CERT_LIST = `${API_VERSION}/certifications`;
const REVIEWS_LIST = `${API_VERSION}/reviews`;
const INSIGHTS = `${API_VERSION}/insights`;
const FORGET = `${API_VERSION}/auth/forget`;
const RESET = `${API_VERSION}/auth/reset`;
const CONTACT = `${API_VERSION}/contact`;
const PROFILE = `${API_VERSION}/profile`;
const VERIFY = `${API_VERSION}/auth/verify`;

export const API_URL = {
  USER_LIST,
  USER_LIST_EXPORT,
  COMPANIES_LIST_EXPORT,
  CITIES_LIST,
  COMPANIES_LIST,
  COMPANIES_LIST_APPROVED,
  COMPANIES_TYPE,
  EVENTS_LIST,
  DOCUMENTS_LIST,
  CATEGORY_LIST,
  REVIEWS_LIST,
  CERT_LIST,
  INSIGHTS,
  API_VERSION,
  RESET,
  CONTACT,
  PROFILE,
  VERIFY,
  FORGET,
};
