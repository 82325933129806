export const PERSONAL_INFO_MODAL = "PERSONAL_INFO_MODAL";
export const Delete_ACCOUNT_MODAL = "Delete_ACCOUNT_MODAL";
export const SHARE_MODAL = "SHARE_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
export const USER_INFO_MODAL = "USER_INFO_MODAL";
export const COMPANY_INFO_MODAL = "COMPANY_INFO_MODAL";
export const EVENT_INFO_MODAL = "EVENT_INFO_MODAL";
export const EVENT_ATTENDEES_MODAL = "EVENT_ATTENDEES_MODAL";
export const REVIEWS_MODAL = "REVIEWS_MODAL";
export const CERT_MODAL = "CERT_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const CERTIFICATE_MODAL = "CERTIFICATE_MODAL";
export const SETTINGS_MODAL = "SETTINGS_MODAL";


export const modules = {
    toolbar: [
        [{ header: [1, 2, false] }], // Header options
        ["bold", "italic", "underline"], // Text styles
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], // Alignment
        // Text direction
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        ["clean"], // Clear formatting
        [{ table: true }], // Table button
    ],
    // "better-table": {
    //     operationMenu: {
    //       items: {
    //         insertColumnRight: {
    //           text: "Insert column right",
    //         },
    //         insertColumnLeft: {
    //           text: "Insert column left",
    //         },
    //         insertRowUp: {
    //           text: "Insert row above",
    //         },
    //         insertRowDown: {
    //           text: "Insert row below",
    //         },
    //         mergeCells: {
    //           text: "Merge selected cells",
    //         },
    //         unmergeCells: {
    //           text: "Unmerge selected cells",
    //         },
    //         deleteColumn: {
    //           text: "Delete selected column",
    //         },
    //         deleteRow: {
    //           text: "Delete selected row",
    //         },
    //         deleteTable: {
    //           text: "Delete table",
    //         },
    //       },
    //     },
    //   },
};

// Configure formats to enable alignment and direction
export const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "align", // Enable alignment
    "direction", // Enable text direction
    "list",
    "bullet",
    "link",
    "image",
    "table", // Enable table support

];