import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getIsAdmin } from "utility/helpers/authHelper";

const MobileDrawer = ({ links, accountLinks }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {links
          .filter((item) => {
            if (getIsAdmin()) {
              return !item.justUser;
            } else {
              return !item.justAdmin;
            }
          })
          .map((item, index) => (
            <ListItem
              key={item.link}
              component={Link}
              to={item.link}
              disablePadding
            >
              <ListItemButton>
                <ListItemText primary={t(item.text)} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
      <List>
        {accountLinks.map((item, index) => (
          <ListItem
            key={item.text}
            component={Link}
            // to={item.link}
            disablePadding
          >
            <ListItemButton onClick={item.onClick}>
              <ListItemText primary={t(item.text)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Toolbar className="drawer" >
          {/* Header content */}
          <h2>{t("header.mainmenu")}</h2>
        <LanguageSwitcher />

        </Toolbar>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default MobileDrawer;
