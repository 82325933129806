import React, { useState } from "react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import './LanguageSwitcher.scss'
import { color } from "highcharts/highcharts";
const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "en");

  const changeLanguage = (lng) => {
    if (i18n.language === lng) return; // Prevent unnecessary reload if already in the chosen language

    setLanguage(lng);
    i18n.changeLanguage(lng);
    document.cookie = `i18next=${lng};path=/`;
    localStorage.setItem("i18nextLng", lng);
    document.body.style.direction = lng === "ar" ? "rtl" : "ltr";

    // Reload the page to ensure the language change is applied
    window.location.reload();
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ maxWidth: "120px", textAlign: "center" }}
      sx={{marginTop: "12px;"}}
    >
      <InputLabel id="language-switcher-label">{t("Language")}</InputLabel>
      <Select
        labelId="language-switcher-label"
        className="language-switcher-select"
        value={language}
        sx={{
          color: "#fff",
          "label":{
            color: "#fff"
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white", // Change the border color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          ".MuiSvgIcon-root": {
            color: "white", // Change the dropdown icon color
          },
        }}
        onChange={(e) => changeLanguage(e.target.value)}
        label={t("Language")}
      >
        <MenuItem value="en">{t("English")}</MenuItem>
        <MenuItem value="ar">{t("Arabic")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
