import { isArabic } from "utils/utils.js";
import API from "./ApiBase.jsx";
import { API_URL } from "utility/constants/APIConst.jsx";

export const updateDocumentDetails = async (id, payload) => {
  return API.put(`${API_URL.DOCUMENTS_LIST}/${id}`, payload);
};

export const deletePost = async (id) => {
  return API.delete(`${API_URL.DOCUMENTS_LIST}/${id}`);
};

export const getAllDocuments = async (
  search = "",
  category = "",
  latest = "",
  page
) => {
  const currentLang = isArabic() ? "ar" : "en";

  return API.get(
    `${API_URL.DOCUMENTS_LIST}?query=${search}&category=${category}&latest=${latest}&page=${page}&lang=${currentLang}`
  );
  // return API.get(`${API_URL.DOCUMENTS_LIST}?page=${pages}`);
};

export const getDocumentWithId = async (id) => {
  return API.get(`${API_URL.DOCUMENTS_LIST}/${id}`);
};

export const addDocument = async (payload) => {
  return API.post(`${API_URL.DOCUMENTS_LIST}`, payload);
};

export const getAllCategories = async (pages) => {
  return API.get(`${API_URL.CATEGORY_LIST}`);
};

export const getLastDocument = async () => {
  return API.get(`${API_URL.DOCUMENTS_LIST}?size=1`);
};

export const getCategoryWithId = async (id) => {
  return API.get(`${API_URL.CATEGORY_LIST}/${id}`);
};
